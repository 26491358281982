import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { IMPACT_URL, BLOG_HOME_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "21.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2rCgf//EABYQAQEBAAAAAAAAAAAAAAAAABEAEP/aAAgBAQABBQIYz//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEQITH/2gAIAQEABj8Cds1x/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAIUEQ/9oACAEBAAE/IRSReGBC8Fz/2gAMAwEAAgADAAAAEPPP/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQMBAT8Qo//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/EIP/xAAcEAABAwUAAAAAAAAAAAAAAAABESExABBBYXH/2gAIAQEAAT8QKRJpUNVKBiCWbPbf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/7f61c/direct-express-team-logos-2020-01_smaller.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/d00b9/direct-express-team-logos-2020-01_smaller.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/8bcb7/direct-express-team-logos-2020-01_smaller.webp 899w"],
            "sizes": "(max-width: 899px) 100vw, 899px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/066f9/direct-express-team-logos-2020-01_smaller.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/4b190/direct-express-team-logos-2020-01_smaller.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/b2161/direct-express-team-logos-2020-01_smaller.jpg 899w"],
            "sizes": "(max-width: 899px) 100vw, 899px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/4a629b38fbb2bcb7222e4a7dce98349d/b2161/direct-express-team-logos-2020-01_smaller.jpg",
            "alt": "logos",
            "title": "logos",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Today the U.S. Department of the Treasury’s `}<a parentName="p" {...{
        "href": "https://www.fiscal.treasury.gov/"
      }}>{`Bureau of the Fiscal Service`}</a>{` announced the selection of Comerica Bank as the financial agent for the `}<a parentName="p" {...{
        "href": "https://www.fiscal.treasury.gov/directexpress/"
      }}>{`Direct Express® prepaid debit card program`}</a>{`. The new agreement is for five years, beginning in January 2020.  Comerica Bank has been the financial agent for the program since inception of the program in 2008.  You can read the full announcement `}<a parentName="p" {...{
        "href": "https://fiscal.treasury.gov/news/comerica-bank-continues-debit-card-for-unbanked.html"
      }}>{`here`}</a>{`.`}</p>
    <p>{`As noted in the release:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Fiscal Service looks forward to continuing to build on the success of the Direct Express® program to provide unbanked beneficiaries with low- to no-cost access to financial resources and education through the PayPerks program – a popular program benefit that cardholders have used more than 14 million times.`}</p>
    </blockquote>
    <p>{`PayPerks for Direct Express launched on the Direct Express program in April of 2013 in `}<a parentName="p" {...{
        "href": "https://www.prnewswire.com/news-releases/mastercard-and-comerica-team-up-with-new-york-city-startup-payperks-to-reward-direct-express-debit-mastercard-cardholders-for-learning-181236481.html"
      }}>{`partnership`}</a>{` with Comerica Bank and Mastercard.  We worked with Comerica and Mastercard on their effort to successfully bid to remain the financial agent in 2015/2016, and now again during this recent cycle.`}</p>
    <p>{`We continue to touch the lives of millions of underserved Americans while also helping the Direct Express program drive awareness & adoption of new features, cardholder engagement, positive behavior change and usage optimization, and creating a sense of trust and loyalty.`}</p>
    <p><strong parentName="p">{`In 2018, active users of PayPerks for Direct Express spent 21% less in fees and took 27% more of their benefit deposit off their card at POS versus cash, compared to cardholders who took no education modules.`}</strong>{`. These changes benefit both the cardholders and the program.`}</p>
    <p>{`We recommend watching our users talk firsthand about the impact of our incentivized financial capability on cardholders.  You can see all of them on our `}<Link to={IMPACT_URL} mdxType="Link">{`impact page`}</Link>{`, or we recommend starting with `}<Link to={`${BLOG_HOME_URL}/2020-04-meet-helen-direct-express-cardholder-financial-capability-payperks`} mdxType="Link">{`Helen`}</Link>{` here.`}</p>
    <p>{`We congratulate our longtime partners Comerica for their successful renewal of their financial agent agreement and thank them for continuing to partner with us in this meaningful and important endeavor.  We also thank the Fiscal Service for its continued recognition of the importance of the work we do as a part of this large and complex program.`}</p>
    <p><em parentName="p">{`For more information, please contact `}<a parentName="em" {...{
          "href": "mailto:press@payperks.com"
        }}>{`press@payperks.com`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      